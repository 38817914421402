import ReactDOM from 'react-dom';
import { createBrowserHistory, Location } from 'history';
import { setInterval } from 'worker-timers';
import { useEffect, useRef } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

declare global {
    interface Window {
        ShowError: (error: string) => void;
    }
}

interface UrlParams {
    [keys: string]: string | number | boolean;
}

export const history = createBrowserHistory();

export const getDateTimeFormatString = () => {
    return (window as any).UserInfo.datePatternPhp.toUpperCase() + ' ' + (window as any).UserInfo.timePatternPhp;
};

export const getDateFormatString = () => {
    return (window as any).UserInfo.datePatternPhp.toUpperCase();
};

export const secondsToTime = (timeInSeconds: number) => {
    const pad = (num: number | string, size: number) =>
        (num as number) >= 100 || (num as string).length >= 3 ? num : ('000' + num).slice(size * -1);
    const time = parseFloat(timeInSeconds.toString());
    const days = Math.floor(time / 60 / 60 / 24);
    const hours = Math.floor(time / 60 / 60) % 24;
    const minutes = Math.floor(time / 60) % 60;
    const seconds = Math.floor(time - (minutes * 60 + hours * 3600 + days * 86400));

    return {
        days: pad(days, 2),
        hours: pad(hours, 2),
        minutes: pad(minutes, 2),
        seconds: pad(seconds, 2),
    };
};

export const secondsToHms = (timeInSeconds: number) => {
    const timeValues = secondsToTime(timeInSeconds);
    const { days, hours, seconds, minutes } = timeValues;

    const dDisplay = Number(days) > 0 ? `${days}d` : '';
    const hDisplay = Number(hours) > 0 ? `${hours}h` : '';
    const mDisplay = Number(minutes) > 0 ? `${minutes}m` : '';
    const sDisplay = Number(seconds) > 0 ? `${seconds}s` : '';

    return dDisplay + hDisplay + mDisplay + (dDisplay ? '' : Number(hours) > 10 ? '' : sDisplay);
};

export const secondsToHm = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);

    const hDisplay = hours > 0 ? `${hours}h` : '';
    const mDisplay = minutes > 0 ? `${minutes}m` : '';
    return timeInSeconds >= 60 ? `${hDisplay} ${mDisplay}`.trim() : '0m';
};

export const secondsToMs = (timeInSeconds: number, space: string = ' ') => {
    const minutes: number = Math.floor(timeInSeconds / 60);
    const seconds: number = timeInSeconds % 60;

    const mDisplay = minutes > 0 ? (minutes < 10 ? `0${minutes}` : `${minutes}`) : '00';
    const sDisplay = seconds > 0 ? (seconds < 10 ? `0${seconds}` : `${seconds}`) : '00';
    return `${mDisplay}${space}:${space}${sDisplay}`;
};

export const replaceUrlVars = (urlVariableValues: UrlParams, url: string): string => {
    Object.keys(urlVariableValues).forEach(variableName => {
        const value = urlVariableValues[variableName];
        const regexp = new RegExp('{' + variableName + '}', 'g');
        url = url.replace(regexp, encodeURIComponent(value));
    });
    const unrecognizedTags = new RegExp('{[^}]*?}', 'g');
    url = url.replace(unrecognizedTags, '');
    return url;
};

export function useInterval(callback: () => any, delay: number | null) {
    const savedCallback = useRef<any>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function formatBytes(num: any) {
    if (num < 1024) {
        return num + 'B';
    } else if (num >= 1024 && num < 1048576) {
        return (num / 1024).toFixed(1) + 'KB';
    } else if (num >= 1048576) {
        return (num / 1048576).toFixed(1) + 'MB';
    }
}

export const formatPhoneNumber = (phoneNumber: string) => {
    const phone = parsePhoneNumberFromString(phoneNumber);
    return phone ? phone.formatInternational() : phoneNumber;
};

/**
 * Convert an image url to a base64 string
 *
 * Credit: https://gist.github.com/oliyh/db3d1a582aefe6d8fee9
 *
 * @param {string} url
 * @return {Promise}
 */
export const convertImgToBase64 = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = () => {
            const reader = new FileReader();
            reader.onloadend = () => {
                resolve(reader.result as string);
            };
            reader.onerror = () => reject();
            reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => reject();
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    });
};

/**
 * Convert object into encoded URL parameters e.g. {a:1,b:2} => "a=1&b=2"
 * Alternative to $.param function
 * @param {UrlParams} params
 * @return {string}
 */
export function urlParams(params: UrlParams): string {
    return Object.keys(params)
        .map(key => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        })
        .join('&');
}

/*
 * Load image url into img tags in HTML based on `cid` data.
 *
 * Also, ensure `image-blot` class name in order for Quill to render the img.
 * @param htmlContent
 * @param media
 */
export const loadImgDataToHTML = (htmlContent: string, media: { filename: string; url: string; size: number; cid: string }[]): string => {
    let htmlResult = htmlContent;
    // load image url to img tag
    media.forEach(attachment => {
        if (attachment.cid) {
            htmlResult = htmlResult.replace(new RegExp(`"cid:${attachment.cid}"`, 'g'), `"${attachment.url}" data-cid="${attachment.cid}"`);
        }
    });

    // ensure that all img element has `image-blot` in className
    // so that Quill can render them with ImageBlot
    const template = document.createElement('template');
    template.innerHTML = htmlResult;
    const imgElements = template.content.querySelectorAll('img');
    imgElements.forEach(element => {
        if (!element.className.includes('image-blot')) {
            element.className = element.className + ' image-blot';
        }
    });
    return template.innerHTML;
};

/**
 * Modify HTML formatting of messages sent to the webchat to preserve emojis and all line breaks
 * @param {string} htmlContent
 * @return {string}
 */
export const normalizeWebchatHTML = (htmlContent: string): string => {
    const parsed = new DOMParser().parseFromString(htmlContent, 'text/html');
    const walker = document.createTreeWalker(parsed.body, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT, null);

    let result = '';
    const lineBreak = '\n';
    const allowedTags = ['BR', 'IMG'];

    let previousNode: Node | null = null;
    while (walker.nextNode()) {
        const node = walker.currentNode;
        if (node.nodeType === Node.TEXT_NODE) {
            if (
                previousNode &&
                previousNode.nodeType === Node.ELEMENT_NODE &&
                (previousNode as Element).tagName === 'DIV' &&
                result.length > 0
            ) {
                result += lineBreak;
            }
            result += (node as Text).textContent;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const elementNode = node as Element;
            if (allowedTags.includes(elementNode.tagName)) {
                if (elementNode.tagName === 'BR') {
                    result += lineBreak;
                } else if (elementNode.tagName === 'IMG' && elementNode.hasAttribute('alt')) {
                    result += elementNode.getAttribute('alt') || '';
                }
            }
        }
        previousNode = node;
    }

    return result.replace(/\n\s*$/, '');
};

/**
 * Replace formatting of html from email clients with Quill's formatting
 * @param {string} htmlContent
 * @param {boolean} reserveWhiteSpace
 * @return {string}
 */
export const normalizeEmailHTML = (htmlContent: string, reserveWhiteSpace?: boolean) => {
    let result = htmlContent;
    if (!reserveWhiteSpace) {
        result = result.replace(/((\r?\n|\r)|<p><br><\/p>|<span><br><\/span>)/gm, '');
    }
    // font size from Outlook
    result = result
        .replace(/font-size: 8pt/gm, 'font-size: 10px')
        .replace(/font-size: 14pt/gm, 'font-size: 14px')
        .replace(/font-size: 24pt/gm, 'font-size: 18px');

    // font size from Gmail
    result = result
        .replace(/<font size="1">/gm, '<span style="font-size: 10px">')
        .replace(/<font size="4">/gm, '<span style="font-size: 18px">')
        .replace(/<\/font>/gm, '</span>');

    // add attribute `target="_blank"` to links
    const template = document.createElement('template');
    template.innerHTML = result;
    const linkElements = template.content.querySelectorAll('a[href]');
    linkElements.forEach(element => {
        element.setAttribute('target', '_blank');
    });
    result = template.innerHTML;

    return result;
};

export const htmlToPlainText = (htmlString: string = '') => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent?.replace(LINEBREAKS_REGEX, ' ') || '';
};

export const getViewUrl = (view: string, location: Location) => {
    const urlParams = new URLSearchParams(location.search);
    urlParams.set('view', view);
    return location.pathname + `?${urlParams.toString()}`;
};
/**
 * Regex to detect HTML tags and line breaks in strings. This regex is currently used
 * for checking if the agent's message field is empty from line breaks and HTML tags.
 */
export const HTML_TAGS_REGEX = /<[^>]*>/g;
export const LINEBREAKS_REGEX = /\s+/g;

/**
 * Basic regex to validate emails. This regex only helps to prevent typos from user.
 * Strict email validation should always be handled in the server.
 */
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const STRICTER_EMAIL_REGEX = /^([\w\-+]+(?:\.[\w\-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})([\w])\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
export const MULTI_PART_TLD_EMAIL_REGEX = /^([\w\-+.]+(?:\.[\w\-+.]+)*)@([\w-]+(?:\.[\w-]+)*\.[a-z]{1,6}(?:\.[a-z]{1,2})?)$/i;
export const PHONE_NUMBER_REGEX = /^\+(\d{1,3})([\d\s])*$/i;

/**
 * Used in omni for manual contact dialog.
 * Basic regex to validate phone numbers in internationl and local formats.
 * Allowed length is between 4 to 16.
 */
export const MANUAL_CONTACT_PHONE_REGEX = /^(?:\+[\d -]{1,15}|[\d -]{4,15})$/i;

/**
 * Regex to validate only email formats with display name like this "Display name <valid@email.com>".
 */
export const MAIL_BOX_FORMAT_REGEX = /^[^<]*<(([\w\-+]+(?:\.[\w\-+]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})([\w])\.([a-z]{2,6}(?:\.[a-z]{2})?))>$/;

/**
 * Download binary from a URL using iframe instead of `window.open`
 * to avoid triggering the `onbeforeunload` event.
 * @param {string} downloadUrl The url to download file
 */
export const downloadFromUrl = (downloadUrl: string) => {
    let downloadIframe = document.querySelector('#leaddesk-download-iframe');
    if (!downloadIframe) {
        downloadIframe = document.createElement('iframe');
        downloadIframe.setAttribute('id', 'leaddesk-download-iframe');
        downloadIframe.setAttribute('style', 'display: none');
        document.body.appendChild(downloadIframe);
    }
    downloadIframe.setAttribute('src', downloadUrl);
};

/**
 * Render contact name based on first and last name.
 * @param {string} firstName contact's first name
 * @param {string} lastName contact's last name
 * @param {string} fallbackText Text to display if contact has no name. Default value: "Unknown" in English.
 * @return {string}
 */
export const renderContactName = (firstName: string, lastName: string, fallbackText?: string) => {
    return (firstName && firstName.length) || (lastName && lastName.length) ? `${firstName || ''} ${lastName || ''}` : fallbackText;
};

export const blobToFile = (blob: Blob, fileName: string, fileType: string): File => {
    return new File([blob], fileName, { type: fileType });
};

export const dataURItoBlob = (fileContentString: string, fileType: string): Blob => {
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(fileContentString.length);
    for (var i = 0; i < fileContentString.length; i++) {
        ia[i] = fileContentString.charCodeAt(i);
    }

    return new Blob([ia], { type: fileType });
};

export const showApiError = (err: any) => (window as any).ShowError((err.response && err.response.data.description) || err.message);

export const copyToClipboard = (textToCopy: string) => {
    if (navigator.clipboard) {
        return navigator.clipboard.writeText(textToCopy);
    } else if ((window as any).clipboardData) {
        //Internet Explorer
        (window as any).clipboardData.setData('Text', textToCopy);
    }
};

export const groupById = (items: any[]) => {
    const filteredItems: Record<string, any> = {};

    items.forEach(item => {
        filteredItems[item.id] = item;
    });

    return filteredItems;
};

export const objectSlice = (object: any, start: number, end: number) => {
    let slice: any = {};

    Object.keys(object)
        .slice(start, end)
        .map((key: string) => {
            slice[key] = object[key];
        });

    return slice;
};

export const objectReverse = (object: any) => {
    let reversed: any = {};

    Object.keys(object)
        .reverse()
        .map((key: string) => {
            reversed[key] = object[key];
        });

    return reversed;
};

export const validateFileSize = (files: File[] | FileList, container: File[]) => {
    const limit = 20 * 1024 * 1024;
    const fileList = Array.from(files);
    const remainingSize = limit - (container.length ? container.reduce((totalSize, file) => totalSize + file.size, 0) : 0);
    return fileList.length && fileList.reduce((totalSize, file) => totalSize + file.size, 0) > remainingSize;
};

export const findAdvancedSelectOption = (options: any, value: number | null | undefined) => {
    const match = options.find((option: any) => value === option.id);
    if (match) {
        return { value: match.id.toString(), label: match.name };
    }
};

/**
 * Replace parameter names found in a string with given values.
 * @param {string} str a string that contains parameter names in curly braces
 * @param {object} params parameters as an array of key-value pairs. For example: { key1: "something", key2: 42 }
 * @return {string} the string with parameters inserted
 */
export const replaceParams = (str: string, params: Record<string, any>) => {
    Object.keys(params).map((key: string) => {
        str = str.replace('{' + key + '}', params[key]);
    });
    return str;
};

/**
 * Inserts a period into the version string of the LeadDesk Windows App.
 * @param {string} version the version string extracted from the user agent, e.g. "940"
 * @return {string} formatted version, e.g. "9.40"
 */
export const formatAppVersion = (version: string) => {
    return version ? version.slice(0, -2) + '.' + version.slice(-2) : '';
};

export const formatDatePickerInput = (input: string) => {
    input.replace(underscoreRegEx, '');
    return input;
};

// Regex to identify when a string contains a character that is NOT: a-z, A-Z, 0-9 or an underscore.
// used by KeyboardDatePicker to reject values that match the regex.
export const underscoreRegEx = /[^. ,[a-zA-Z0-9_]*$]+/gi;
/**
 * Pause code execution for the given time. Callee must handle the returned promise.
 *
 * @param {number} ms milliseconds
 * @return {Promise}
 */
export const delayExecution = async (ms: number): Promise<any> => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const randomString = (length: number) => {
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomResult = '';
    for (let i = 0; i < length; i++) {
        const random = Math.floor(Math.random() * chars.length);
        randomResult += chars.substring(random, random + 1);
    }
    return randomResult;
};

/**
 * fetch request with timeout
 * @param {string} url url
 * @param {json} options request options
 * @param {number} timeout timeout
 * @return {Promise}
 */
export const fetchWithTimeout = async (url: string, options: any = {}, timeout: number = 5000) =>
    Promise.race([fetch(url, options), new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))]);

/**
 * Adds a dot at the end of the string.
 *
 * @param {string} str String to add dot at the end
 * @returns {string} same string with appended "."
 */
export const appendDot = (str: string): string => {
    const formattedStr = str.trim();

    if (formattedStr.length <= 0) {
        return formattedStr;
    }

    const hasDot = formattedStr.slice(-1) === '.';

    return hasDot ? formattedStr : formattedStr + '.';
};

/**
 * Tries to unmount react component at node by id.
 *
 * @param {string} id element id
 * @returns {void}
 */
export const umountReactComponentByElementId = (id: string): void => {
    const dialogElement = document.getElementById(id);
    if (dialogElement) {
        ReactDOM.unmountComponentAtNode(dialogElement);
    }
};

/**
 * Removes all characters that cannot be present in an email address.
 *
 * @param {string} email email address
 * @returns {string}
 */
export const filterEmailAddress = (email: string): string => {
    return email
        .replace(/\\u[\dA-F]{4}/gi, match => String.fromCharCode(parseInt(match.slice(2), 16)))
        .replace(/[\u{0080}-\u{10FFFF}]/gu, '')
        .replace(/[^\w.+-@]/g, '');
};

/**
 * Removes all characters that cannot be present in a phone number.
 *
 * @param {string} phone phone number
 * @returns {string}
 */
export const filterPhoneNumber = (phone: string): string => {
    return (phone.includes('+') ? '+' : '') + phone.replace(/\D/g, '');
};

/**
 * Returns if the provided email address is valid or not.
 *
 * @param {string} email email address
 * @returns {string}
 */
export const isValidEmailAddress = (email: string): boolean => {
    return email.length === 0 || STRICTER_EMAIL_REGEX.test(email);
};
