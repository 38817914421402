import React from 'react';
import { useEffect, useRef } from 'react';
import { Sso } from '../../Sso';

interface GoogleLoginButtonProps {
    clientId: string;
    uiMode?: string;
}

/**
 * Lazy initialize google accounts library
 */
const initialize = (() => {
    var promise: Promise<void>;
    return (clientId: string, uiMode?: string): Promise<void> => {
        if (!promise) {
            promise = new Promise<void>(resolve => {
                window.onGoogleLibraryLoad = () => {
                    window.google.accounts.id.initialize({
                        client_id: clientId,
                        callback: res => {
                            Sso.submitIdToken('?page=sso&provider=google&type=popup' + (uiMode ? `&${uiMode}` : ''), res.credential);
                        },
                    });
                    resolve();
                };
            });
        }
        return promise;
    };
})();

/**
 * SSO button for Google login
 * @param {GoogleLoginButtonProps} props
 * @returns {JSX.Element}
 */
export function GoogleLoginButton(props: GoogleLoginButtonProps): JSX.Element {
    const { clientId, uiMode } = props;
    const divRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        initialize(clientId, uiMode)
            .then(() => {
                window.google.accounts.id.renderButton(divRef.current, {
                    type: 'standard',
                    width: 300,
                    logo_alignment: 'center',
                    theme: 'outline',
                    size: 'medium',
                });
            })
            .catch(err => console.error('GoogleLoginButton', err));
    }, []);

    return <div className="sso-button sso-button-google" ref={divRef} />;
}
